<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    isActive?: boolean;
  }>(),
  {
    isActive: true,
  },
);
</script>

<template>
  <div
    v-if="props.isActive"
    data-spec="loading-modal"
    class="bg-black bg-opacity-50 fixed z-30 left-0 top-0 right-0 bottom-0 flex justify-center items-center"
  >
    <div class="spinner block w-32 h-32" data-cy="loading">
      <svg width="1000" height="1000" viewBox="0 0 1000 1000" class="block !w-32 !h-32">
        <defs>
          <radialGradient
            id="radial-gradient"
            cx="0.5"
            cy="0.5"
            r="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0" stop-color="#fff" stop-opacity="1" />
            <stop offset="1" stop-color="#99a0cb" stop-opacity="1" />
          </radialGradient>
          <clipPath id="clip-path">
            <circle
              id="Ellipse_1"
              data-name="Ellipse 1"
              cx="365.5"
              cy="365.5"
              r="365.5"
              transform="translate(107 135)"
              fill="#fff"
              stroke="#707070"
              stroke-width="1"
            />
          </clipPath>
          <clipPath id="outer-clip">
            <circle cx="500" cy="500" r="404" stroke="none" />
          </clipPath>
          <clipPath id="clip-Loading_screen_1">
            <rect width="1000" height="1000" />
          </clipPath>
        </defs>
        <g
          id="Loading_screen_1"
          data-name="Loading screen – 1"
          clip-path="url(#clip-Loading_screen_1)"
        >
          <g
            id="Ellipse_3"
            data-name="Ellipse 3"
            transform="translate(92 92)"
            stroke="rgba(153,160,203,0.2)"
            stroke-width="13"
            fill="url(#radial-gradient)"
          >
            <circle cx="408" cy="408" r="408" stroke="none" />
            <circle cx="408" cy="408" r="401.5" fill="none" />
          </g>
          <g clip-path="url(#outer-clip)">
            <g
              id="Water_Back"
              data-name="Water Back"
              transform="translate(28)"
              clip-path="url(#clip-path)"
            >
              <g id="Water" transform="translate(107.133 414.945)">
                <path
                  id="Path_1"
                  data-name="Path 1"
                  d="M838.111,519.069S770.535,664.3,636.206,639.4,433.065,449.556,300.8,419.473s-193.664,99.6-193.664,99.6V903.726H838.111Z"
                  transform="translate(737.867 0)"
                  fill="#01d2c5"
                />
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M838.107,518.953S770.429,664.3,636.1,639.4,432.959,449.556,300.691,419.473s-193.664,99.6-193.664,99.6V903.721l731.083.029Z"
                  transform="translate(7.027 0)"
                  fill="#01d2c5"
                />
              </g>
            </g>
            <g
              id="Water_Front"
              data-name="Water Front"
              transform="translate(28)"
              clip-path="url(#clip-path)"
            >
              <g id="Water-2" data-name="Water" transform="translate(-731)">
                <path
                  id="Path_3"
                  data-name="Path 3"
                  d="M107.133,519.069S174.709,664.3,309.038,639.4,512.179,449.556,644.447,419.473s193.664,99.6,193.664,99.6V903.726H107.133Z"
                  transform="translate(0 0)"
                  fill="#f163c1"
                />
                <path
                  id="Path_4"
                  data-name="Path 4"
                  d="M107.031,518.953S174.709,664.3,309.038,639.4s203.141-189.846,335.409-219.93,193.664,99.6,193.664,99.6V903.721l-731.083.029Z"
                  transform="translate(731 0)"
                  fill="#f163c1"
                />
              </g>
            </g>
            <path
              id="Subtraction_1"
              data-name="Subtraction 1"
              d="M490.04,791c-12.275,0-24.664-.589-36.824-1.75-11.994-1.146-24.057-2.876-35.854-5.142C574.713,748.895,688.92,585.462,688.92,395.5S574.713,42.105,417.362,6.892c11.8-2.268,23.867-4,35.854-5.142C465.376.589,477.765,0,490.04,0a388.735,388.735,0,0,1,39.769,2.042,383.028,383.028,0,0,1,177.7,65.5A394.466,394.466,0,0,1,812.572,174.372,396.9,396.9,0,0,1,871.1,315.793c2.6,12.909,4.581,26.121,5.894,39.269C878.325,368.4,879,382.008,879,395.5s-.676,27.1-2.008,40.438c-1.313,13.148-3.3,26.36-5.894,39.27A396.677,396.677,0,0,1,765.076,675.161a393.249,393.249,0,0,1-57.565,48.294,387.01,387.01,0,0,1-66.071,36.465,381.43,381.43,0,0,1-111.632,29.039A388.746,388.746,0,0,1,490.04,791Z"
              transform="translate(17 104)"
              fill="rgba(255,255,255,0.34)"
            />
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<style scoped>
@keyframes leftToRight {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-731px);
  }
}

#Water {
  animation: leftToRight;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

#Water-2 {
  animation: leftToRight;
  animation-duration: 2s;
  animation-direction: reverse;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}

.spinner {
  width: 224px;
}

svg {
  width: 100%;
  height: auto;
}
</style>

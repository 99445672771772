<script setup lang="ts">
import Modal from '@/core/components-v2/modals/Timed/Modal.vue';
import storage, { LAST_ACTIVITY, STORAGE_REFRESH_TOKEN } from '@/core/helpers/storage';
import router from '@/router';
import { useAuthStore } from '@/stores/auth/auth-store';
import { onBeforeUnmount, onMounted, ref } from 'vue';

const defaultLifetime = 8000; // seconds
const authStore = useAuthStore();

const timeToExpiry = ref(defaultLifetime);
const intervalId = ref(0);

onMounted(() => {
  // @ts-expect-error
  intervalId.value = setInterval(() => {
    const currentTime = Math.floor(Date.now());
    const lastActivity = storage.get<number>(LAST_ACTIVITY);
    if (lastActivity === null) return;
    const timeSinceLastActivity = currentTime - lastActivity;
    timeToExpiry.value = defaultLifetime - Math.floor(timeSinceLastActivity / 1000);
    //console.log('timeToExpiry', timeToExpiry.value);

    // Sign out on inactivity:
    if (timeToExpiry.value <= 0) {
      console.log('timeToExpiry.value <= 0');
      handleSignOut();
    }
    // If we've been logged out on another tab by the above:
    if (!storage.get(STORAGE_REFRESH_TOKEN)) {
      console.log('!authStore.refreshToken');
      handleSignOut();
    }
  }, 1000);
});

onBeforeUnmount(() => {
  clearInterval(intervalId.value);
});

const handleStaySignedIn = () => {
  // No action needed. Global listener resets timer on click.
};

const handleSignOut = () => {
  clearInterval(intervalId.value);
  authStore.destroyTokens();
  router.push({ name: 'login', query: { why: 'inactivity' } });
};
</script>
<template>
  <Modal
    v-if="timeToExpiry < 60"
    :seconds="timeToExpiry"
    status="warning"
    title="Session about to expire"
    message="Your session is about to expire due to inactivity.<br>Do you want us to keep you signed in?"
    cancelButtonText="No, sign me out"
    primaryButtonText="Yes, keep me signed in"
    @success="handleStaySignedIn"
    @cancel="handleSignOut"
  />
</template>

import { createApp } from 'vue';
import { createPinia } from 'pinia';

// i18n support
import i18n from '@/locales/i18n';

import Toast, { type PluginOptions, POSITION } from 'vue-toastification';

import App from '@/App.vue';
import router from '@/router';
import vClickout from 'vue3-clickout';
import '@/assets/app-styles.scss';
import 'vue-toastification/dist/index.css';
import 'nprogress/nprogress.css';

import './assets/themes/lara-light/mobilityways/theme.scss';
import 'primevue/resources/primevue.min.css';

import * as Sentry from '@sentry/vue';
import { getEnv } from '@/core/helpers/generic-helpers';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { vueQueryOptions } from '@/api/vue-query-options';
import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import Tooltip from 'primevue/tooltip';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(isSameOrAfter);

const mw = createApp(App);
const pinia = createPinia();

console.log(`Vite Env: ${import.meta.env.MODE}
Run Env: ${getEnv()}
Git SHA: ${__COMMIT_HASH__}`);

/**
 * Configure sentry for error reporting on production & development
 * mobilityways-dashboard-web project:
 */
Sentry.init({
  app: mw,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: getEnv(),
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  // @todo: If we definitely don't want any backend instrumentation, remove these
  tracePropagationTargets: [
    'https://interface.liftshare.com',
    'https://interface-dev.liftshare.com',
    'https://interface-staging.liftshare.com',
  ],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // True for development-dashboard.mobilityways.co.uk and dashboard.mobilityways.com
  enabled: import.meta.env.MODE === 'production',
});

const options: PluginOptions = {
  position: POSITION.BOTTOM_RIGHT,
  timeout: 5000,
};

mw.use(pinia);
mw.use(i18n);
mw.use(ConfirmationService);
mw.use(router);
mw.use(VueQueryPlugin, vueQueryOptions);
mw.use(PrimeVue);
mw.directive('tooltip', Tooltip);
mw.use(vClickout);
mw.use(Toast, options);
mw.mount('#app');
